import { useTranslation } from 'react-i18next';
import { Citation, CitationMetadata, Metadata } from '../../../types';
import './AnswerReferences.scss';

const ReferenceItem = ({
  reference,
  index,
}: {
  reference: Citation;
  index: number;
}) => {
  /*  const [isOpen, setIsOpen] = useState(false); */
  const { t } = useTranslation();

  const createReferenceFilepath = (
    reference: Citation,
    index: number,
    truncate = false,
  ): string => {
    const filePathTruncationLimit = 50;

    const formatTemplates = {
      filepathWithMetadata: (
        fileName: string,
        metadata: Metadata | CitationMetadata,
      ) =>
        //To whom it may concern: I apologize for the template string chaos below.
        truncate && fileName!.length > filePathTruncationLimit
          ? `${fileName!.substring(0, 20)}...${fileName!.substring(
              fileName!.length - 20,
            )} - ${t('messages.reference_panel.page')} ${metadata.start_page}`
          : `${fileName} - ${t('messages.reference_panel.page')} ${
              metadata.start_page
            }`,
      filepathWithReindexId: (fileName: string, reindex: string) =>
        `${fileName} - ${t('messages.reference_panel.part')} ${reindex}`,
      default: (index: number) =>
        `${t('messages.reference_panel.citation')} ${index}`,
    };

    const metadata = reference.metadata;
    const { reindex_id } = reference;
    const fileName = reference.filepath?.split('/').pop();

    // TODO: fix chunk_id from above reference to show different ID's from same document.
    if (fileName && metadata) {
      return formatTemplates.filepathWithMetadata(fileName, metadata);
    } else if (fileName && reindex_id) {
      return formatTemplates.filepathWithReindexId(fileName, reindex_id);
    } else {
      return formatTemplates.default(index);
    }
  };

  return (
    <>
      <a
        data-metrics-id={`ReferenceLink-${reference.title}`}
        className='reference-button'
        href={reference.url ?? undefined}
        onClick={(e) => {
          e.preventDefault();
          if (reference.url && reference.metadata?.start_page) {
            const baseUrl = reference.url.split('#')[0];
            const pageNumber = parseInt(reference.metadata.start_page);
            if (!isNaN(pageNumber)) {
              const url = `${baseUrl}#page=${pageNumber}&zoom=page-fit&pagemode=thumbs`;
              window.open(url, '_blank', 'noopener,noreferrer');
            } else {
              window.open(reference.url, '_blank', 'noopener,noreferrer');
            }
          } else if (reference.url) {
            window.open(reference.url, '_blank', 'noopener,noreferrer');
          }
        }}
      >
        {reference.reindex_id && (
          <span className='reference-id-badge'>{reference.reindex_id}</span>
        )}
        {createReferenceFilepath(reference, index, true)}
      </a>
      {/* TODO: Add this back when we can get proper citation content */}
      {/* <div className='reference-list-item'>
      <button className='reference-button' onClick={() => setIsOpen(!isOpen)}>
        {reference.reindex_id && (
          <span className='reference-id-badge'>{reference.reindex_id}</span>
        )}
        {createReferenceFilepath(reference, index, true)}
      </button>
      
       {isOpen && (
        <div className='reference-content'>
          {reference.url && (
            <a
              href={reference.url}
              target='_blank'
              rel='noopener noreferrer'
              className='references-list-item-link'
            >
              <img
                className='reference-url-icon'
                src={`/icons/document.svg`}
                aria-hidden='true'
              />
              See document
            </a>
          )}
          <p className='reference-text'>{`" ... ${reference.content} ... "`}</p>
        </div>
      )} 
    </div>*/}
    </>
  );
};

export const AnswerReferences = ({
  references,
}: {
  references: Citation[];
}) => {
  const { t } = useTranslation();
  return (
    <div className='answer-references-section'>
      <p className='answer-reference-list-heading'>
        {t('messages.reference_panel.heading')}
      </p>
      <div className='references-panel-list'>
        {references.map((reference, index) => (
          <ReferenceItem key={index} reference={reference} index={index} />
        ))}
      </div>
    </div>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { ThumbsUpIcon } from './ThumbsUpIcon';
import { ThumbsDownIcon } from './ThumbsDownIcon';
import './FeedbackPanel.scss';
import { useDynamicInputHeight } from '../../../hooks/use-dynamic-input-height';
import { useApiContext } from '../../../api/useApiContext';
import { Feedback } from '../../../types';
import { useAppContext } from '../../../state/useAppProvider';
import { CheckIcon } from './CheckIcon';
import { useAuthContext } from '../../../api/useAuthContext';
import { useTranslation } from 'react-i18next';

type FeedbackFormContent = {
  heading: string;
  quickChoices: FeedbackQuickChoice[];
};

type FeedbackPanelProps = {
  conversationId: string;
  messageId: string;
};

type FeedbackFormProps = {
  feedbackRating: 'positive' | 'negative';
} & FeedbackPanelProps;

type PositiveFeedbackLocale =
  | 'feedback.positive.options.solved'
  | 'feedback.positive.options.correct'
  | 'feedback.positive.options.comprehensive';

type NegativeFeedbackLocale =
  | 'feedback.negative.options.dangerous'
  | 'feedback.negative.options.inaccurate'
  | 'feedback.negative.options.missing_info'
  | 'feedback.negative.options.other';

type FeedbackQuickChoice = {
  label: PositiveFeedbackLocale | NegativeFeedbackLocale;
  value: string;
};

type FeedbackChoiceList = {
  positive: FeedbackQuickChoice[];
  negative: FeedbackQuickChoice[];
};

const quickChoices: FeedbackChoiceList = {
  positive: [
    { label: 'feedback.positive.options.solved', value: 'Solved the problem' },
    { label: 'feedback.positive.options.correct', value: 'Correct' },
    {
      label: 'feedback.positive.options.comprehensive',
      value: 'Easy to understand',
    },
  ],
  negative: [
    {
      label: 'feedback.negative.options.dangerous',
      value: 'Dangerous/unsafe',
    },
    { label: 'feedback.negative.options.inaccurate', value: 'Inaccurate' },
    {
      label: 'feedback.negative.options.missing_info',
      value: 'Missing information',
    },
    { label: 'feedback.negative.options.other', value: 'Other' },
  ],
};

const FeedbackChoice = ({
  choice,
  selected,
  selectedCallback,
}: {
  choice: FeedbackQuickChoice;
  selected: boolean;
  selectedCallback: (value: string, remove: boolean) => void;
}) => {
  const toggleAddFeedbackChoice = () => {
    selectedCallback(choice.value, selected);
  };
  const { t } = useTranslation();

  return (
    <button
      data-metrics-id={`FeedbackChoice-${choice.value}`}
      className={`feedback-panel-choice ${selected && 'selected'}`}
      onClick={toggleAddFeedbackChoice}
    >
      {t(choice.label)}
    </button>
  );
};

const FeedbackCompleteMessage = ({ hideMessage }: { hideMessage: boolean }) => {
  const { t } = useTranslation();
  return (
    <div className={`masking-wrapper ${hideMessage && 'hidden'}`}>
      <div className={`feedback-panel-complete ${hideMessage && 'hidden'}`}>
        <CheckIcon className='feedback-check' />
        <div>
          <h2 className='feedback-panel-form-heading'>
            {t('feedback.complete_panel.title')}
          </h2>
          <p className='feedback-panel-complete-message'>
            {t('feedback.complete_panel.message')}
          </p>
        </div>
      </div>
    </div>
  );
};

const FeedbackForm = ({
  feedbackRating,
  conversationId,
  messageId,
}: FeedbackFormProps) => {
  const { t } = useTranslation();
  const { uploadFeedback } = useApiContext();
  const { dispatch } = useAppContext();
  const { userData } = useAuthContext();
  const [feedbackChoices, setFeedbackChoices] = useState<string[]>([]);
  const feedbackFormContent: FeedbackFormContent = {
    heading: t(`feedback.${feedbackRating}.question`),
    quickChoices: quickChoices[feedbackRating],
  };

  const [writtenFeedback, setWrittenFeedback] = useState<string>('');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  useDynamicInputHeight(inputRef);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hideSubmitMessage, setHideSubmitMessage] = useState(false);

  const onWrittenFeedbackChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setWrittenFeedback(event.target.value);
  };

  const onToggleFeedbackChoice = useCallback(
    (value: string, remove: boolean) => {
      if (remove) {
        setFeedbackChoices((prev) => prev.filter((choice) => choice !== value));
      } else {
        setFeedbackChoices((prev) => [...prev, value]);
      }
    },
    [],
  );

  const onFormSubmit = async () => {
    if (!userData) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: t('errors.no_user_data_found'),
      });
      return;
    }
    setIsSubmitting(true);
    const feedback: Feedback = {
      conversation_id: conversationId,
      message_id: messageId,
      feedback_rating: feedbackRating,
      feedback_choices: feedbackChoices,
      feedback: writtenFeedback,
    };

    const responseCode = await uploadFeedback(feedback);
    if (responseCode === 200) {
      setSubmitted(true);
      setTimeout(() => setHideSubmitMessage(true), 4000);
    } else {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: t('errors.feedback_submit_failed'),
      });
    }
    setIsSubmitting(false);
  };

  // Set feedback form content based on feedback choice
  useEffect(() => {
    setFeedbackChoices([]);
  }, [feedbackRating]);

  return submitted ? (
    <FeedbackCompleteMessage hideMessage={hideSubmitMessage} />
  ) : (
    <div className={`feedback-panel-form ${isSubmitting && 'loading'}`}>
      <h2 className='feedback-panel-form-heading'>
        {feedbackFormContent.heading}
      </h2>
      <div className='feedback-panel-choices'>
        {feedbackFormContent.quickChoices.map((choice, index) => (
          <FeedbackChoice
            key={index}
            choice={choice}
            selected={feedbackChoices.includes(choice.value)}
            selectedCallback={onToggleFeedbackChoice}
          />
        ))}
      </div>
      <textarea
        ref={inputRef}
        onChange={onWrittenFeedbackChange}
        value={writtenFeedback}
        className='feedback-panel-textarea'
        placeholder={t('feedback.placeholder')}
      />
      <button
        data-metrics-id='FeedbackSubmitButton'
        onClick={onFormSubmit}
        className='feedback-panel-submit'
      >
        {t('feedback.submit')}
      </button>
    </div>
  );
};

export const FeedbackPanel = ({
  conversationId,
  messageId,
}: FeedbackPanelProps) => {
  const { t } = useTranslation();
  const [feedbackRating, setFeedbackRating] = useState<
    'positive' | 'negative' | null
  >(null);

  const onNegativeChoice = () => {
    if (feedbackRating === 'negative') {
      setFeedbackRating(null);
    } else {
      setFeedbackRating('negative');
    }
  };
  const onPositiveChoice = () => {
    if (feedbackRating === 'positive') {
      setFeedbackRating(null);
    } else {
      setFeedbackRating('positive');
    }
  };

  return (
    <div className='feedback-panel'>
      <div className='feedback-panel-actions'>
        <button
          data-metrics-id='PositiveFeedbackButton'
          onClick={onPositiveChoice}
          aria-label={t('feedback.positive.button_label')}
          className={`feedback-panel-action ${
            feedbackRating === 'positive' && 'selected'
          }`}
        >
          <ThumbsUpIcon selected={feedbackRating === 'positive'} />
        </button>
        <button
          data-metrics-id='NegativeFeedbackButton'
          onClick={onNegativeChoice}
          aria-label={t('feedback.negative.button_label')}
          className={`feedback-panel-action ${
            feedbackRating === 'negative' && 'selected'
          }`}
        >
          <ThumbsDownIcon selected={feedbackRating === 'negative'} />
        </button>
      </div>
      {feedbackRating && (
        <FeedbackForm
          conversationId={conversationId}
          messageId={messageId}
          feedbackRating={feedbackRating}
        />
      )}
    </div>
  );
};

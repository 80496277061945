import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigateWithTracker } from '../../hooks/use-navigate-with-tracker';
import { CloseIcon } from '../../components/error-popup-handler/CloseIcon';
import { RequestForm } from '../../components/information-menu/RequestForm';
import { Pages } from '../constants/pages';
import { useAuthContext } from '../../api/useAuthContext';
import { ChatLoader } from '../../components/chat/chat-loader';
import { Disclaimer } from '../../components/information-menu/Disclaimer';
import { IncidentReportForm } from '../../components/information-menu/IncidentReportForm';
import { getVideoUrl } from '../../utils/videoUrlHelper';
import './Information.scss';

export const InformationPage = () => {
  const { t } = useTranslation();
  const { userSettings, loadingUser } = useAuthContext();
  
  return loadingUser ? (
    <ChatLoader message={t('settings.loading')} />
  ) : (
    <>{userSettings && <Information />}</>
  );
};

const Information = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigateWithTracker();
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showIncidentForm, setShowIncidentForm] = useState(false);

  return (
    <div className="settings-container">
      <div className="settings-header-section">
        {showIncidentForm ? (
          <>
            <h2 className="settings-header">{t('information_menu.report_incident')}</h2>
            <button
              onClick={() => setShowIncidentForm(false)}
              className="settings-close-button"
              aria-label={t('common.close')}
            >
              <CloseIcon />
            </button>
          </>
        ) : showRequestForm ? (
          <>
            <h2 className="settings-header">{t('information_menu.request_products')}</h2>
            <button
              onClick={() => setShowRequestForm(false)}
              className="settings-close-button"
              aria-label={t('common.close')}
            >
              <CloseIcon />
            </button>
          </>
        ) : showDisclaimer ? (
          <>
            <h2 className="settings-header">{t('information_menu.disclaimer')}</h2>
            <button
              onClick={() => setShowDisclaimer(false)}
              className="settings-close-button"
              aria-label={t('common.close')}
            >
              <CloseIcon />
            </button>
          </>
        ) : (
          <>
            <h2 className="settings-header">{t('information_menu.title')}</h2>
            <button
              onClick={() => navigate(Pages.HOME, '/')}
              className="settings-close-button"
              aria-label={t('common.close')}
            >
              <CloseIcon />
            </button>
          </>
        )}
      </div>
      <div className="settings-content">
        {showIncidentForm ? (
          <IncidentReportForm onClose={() => setShowIncidentForm(false)} />
        ) : showRequestForm ? (
          <RequestForm onClose={() => setShowRequestForm(false)} />
        ) : showDisclaimer ? (
          <Disclaimer />
        ) : (
          <div className="info-links">
            <a 
              href="https://avenue.assaabloy.net/library/entrance-systems/business-segments/service/technician-copilot" 
              className="info-link" 
              onClick={(e) => {
                e.preventDefault();
                window.open('https://avenue.assaabloy.net/library/entrance-systems/business-segments/service/technician-copilot', '_blank');
              }}
            >
              {t('information_menu.instructions')}
            </a>

            <a 
              href="#" 
              className="info-link" 
              onClick={(e) => {
                e.preventDefault();
                window.open(getVideoUrl(i18n.language), '_blank');
              }}
            >
              {t('information_menu.video_instructions')}
            </a>

            <a href="#" className="info-link" onClick={(e) => {
              e.preventDefault();
              setShowDisclaimer(true);
            }}>{t('information_menu.disclaimer')}</a>
            <a href="#" className="info-link" onClick={(e) => {
              e.preventDefault();
              setShowIncidentForm(true);
            }}>{t('information_menu.report_incident')}</a>
            <a href="#" className="info-link" onClick={(e) => {
              e.preventDefault();
              setShowRequestForm(true);
            }}>
              {t('information_menu.request_products')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

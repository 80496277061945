const videoUrls: Record<string, string> = {
  'en': 'https://assa-abloy-video.creomediamanager.com/category/e661936b-68c1-4d74-a4b2-443444f2c6d8',
  'sv': 'https://assa-abloy-video.creomediamanager.com/category/ee7873c1-8d62-49bc-96c8-76db921de8eb',
  'fr': 'https://assa-abloy-video.creomediamanager.com/category/751b8097-c7cd-404a-93fa-60cde54cebaf',
  'de': 'https://assa-abloy-video.creomediamanager.com/category/39a7771e-68aa-47c4-a06b-6bd672364d50',
  'nl': 'https://assa-abloy-video.creomediamanager.com/category/b3bb202c-b09a-4e94-a816-fc3343a52cd3',
  'fi': 'https://assa-abloy-video.creomediamanager.com/category/11e8d1da-e61b-4700-973a-7d596758c944',
  'da': 'https://assa-abloy-video.creomediamanager.com/category/1148efe9-5c87-4bd3-9de7-2d4bcfbbf999',
  'es': 'https://assa-abloy-video.creomediamanager.com/category/9ce74a1b-32b0-4cde-b55b-657bc8b55caf',
  'pt': 'https://assa-abloy-video.creomediamanager.com/category/b6d40d82-9d0d-4716-93e4-b961fa05e619',
  'it': 'https://assa-abloy-video.creomediamanager.com/category/b7b96533-b41e-43eb-b69a-cb0913f5b85e',
  'pl': 'https://assa-abloy-video.creomediamanager.com/category/8e4d8d0b-1677-4de1-8f2b-a9ab316aa25b',
};

export const getVideoUrl = (languageCode: string): string => {
  const baseLanguage = languageCode.split('-')[0].toLowerCase();
  return videoUrls[baseLanguage] || videoUrls['en'];
}; 
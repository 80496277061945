import axios, { AxiosError } from 'axios';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Conversation,
  Feedback,
  SpeechToken,
  ConversationSummary,
  VersionInfo,
  ProductChoiceData,
  ProductChoiceDataPrimaryKeys,
  ProductManual,
  BusinessSegment,
  Citation,
  ToolMessage,
} from '../types';
import { Survey, SurveyId } from '../types/survey';
import { AxiosResponse } from 'axios';
import { fetchWithToken } from './utils/fetchWithToken';
import { useAppContext } from '../state/useAppProvider';
import { endpoints } from './constants';
import { nanoid } from 'nanoid';
import { useAuthContext } from './useAuthContext';
import { useTranslation } from 'react-i18next';

export const ApiContext = createContext(
  {} as ReturnType<typeof useProvideApiState>,
);

export const ApiProvider = ({ ...props }) => {
  const apiState = useProvideApiState();

  return (
    <ApiContext.Provider value={apiState}>{props.children}</ApiContext.Provider>
  );
};

const useProvideApiState = () => {
  const { t } = useTranslation();
  const { idToken, getUpdatedIdToken, axiosInstance } = useAuthContext();
  const [sasToken, setSasToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const showAuthMessage = useMemo(
    () => !isLoading && !idToken,
    [isLoading, idToken],
  );
  const { chatHistory, currentChat, dispatch } = useAppContext();

  const abortConversationRequest = useCallback(() => {
    if (!abortControllerRef.current) return;
    abortControllerRef.current.abort();
    abortControllerRef.current = null;

    // Make sure the pending answer is cleared when the conversation is aborted.
    dispatch({ type: 'SET_PENDING_ANSWER', payload: undefined });
  }, [dispatch]);

  const newAbortControllerRef = useCallback((abortBeforeReplace = true) => {
    if (abortControllerRef.current && abortBeforeReplace) {
      abortControllerRef.current.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return abortController;
  }, []);

  const getStorageToken = useCallback(async () => {
    return await axiosInstance
      .get(`/api/get-storage-token`, {
        params: {
          container_name: 'cnt-tcv1-871b-20240221-fullv1wregpdftxt',
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data);
  }, [axiosInstance]);

  // Initializes an SAS Token whenever the ID token is available.
  useEffect(() => {
    if (!sasToken) {
      // TODO: Either remove this entirely or bring back the commented code after discussion about security.
      setSasToken('mock-sas-token');
      /* getStorageToken()
        .then((res) => {
          setSasToken(res.sas_token);
        })
        .catch((err) => {
          console.error('Failed to get storage token', err);
        }); */
    }
  }, [getStorageToken, sasToken]);

  const getConversationStream = async (conversationUpdate?: Conversation) => {
    try {
      const abortController = newAbortControllerRef();
      return await fetchWithToken(getUpdatedIdToken, endpoints.conversation, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(conversationUpdate ?? currentChat),
        signal: abortController.signal,
      }).then((response) => response.body);
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: t('errors.assistant_response_unavailable'),
      });
      return null;
    }
  };

  const conversationHistoryList = useCallback(async () => {
    try {
      setLoadingHistory(true);
      await axiosInstance
        .get<{ conversations: ConversationSummary[] }>(endpoints.historyList, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          dispatch({
            type: 'UPDATE_CHAT_HISTORY_LIST',
            payload: res.data.conversations,
          });
          setLoadingHistory(false);
        });
    } catch (err) {
      setLoadingHistory(false);
      console.error('There was an issue fetching your chat history.');
      return null;
    }
    setLoadingHistory(false);
  }, [axiosInstance, dispatch]);

  // This removes all query parameters from the passed citations.
  const getURLWithoutParams = (citations: Citation[]): Citation[] => {
    return citations.map((citation) => {
      const urlObject = new URL(citation.url);
      urlObject.search = '';
      urlObject.hash = '';
      return {
        ...citation,
        url: decodeURI(urlObject.toString()),
      };
    });
  };

  const getLinkWithToken = useCallback(
    async (url: string) => {
      return await axiosInstance
        .get(`/api/secure-url`, {
          params: {
            blob_url: url,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data);
    },
    [axiosInstance],
  );

  const getSignedToolMessage = useCallback(
    async (citations: Citation[]): Promise<ToolMessage> => {
      const signedUrlCitations: Citation[] = await Promise.all(
        citations.map(async (citation) => {
          try {
            const linkData = await getLinkWithToken(citation.url);
            const signedLink = citation.metadata.start_page
              ? `${linkData.signed_url}#page=${citation.metadata.start_page}`
              : linkData.signed_url;
            return {
              ...citation,
              url: signedLink,
              metadata: citation.metadata,
            };
          } catch (error) {
            console.error(
              `Failed to get signed link for resource: ${citation.url}.\n`,
              `Failed with error: ${error}`,
            );
            return citation;
          }
        }),
      );

      return {
        id: nanoid(),
        citations: signedUrlCitations,
      };
    },
    [getLinkWithToken],
  );

  const updateReferenceLinks = useCallback(
    async (conversation: Conversation): Promise<Conversation> => {
      const updatedMessages = await Promise.all(
        conversation.messages.map(async (message) => {
          if (message.role === 'assistant' && message.tool_message) {
            const cleanUrlCitations = getURLWithoutParams(
              message.tool_message.citations,
            );
            const updatedToolMessage =
              await getSignedToolMessage(cleanUrlCitations);
            return {
              ...message,
              tool_message: updatedToolMessage,
            };
          }
          return message;
        }),
      );
      return { ...conversation, messages: updatedMessages };
    },
    [getSignedToolMessage],
  );

  const historyRead = useCallback(
    async (
      convId: string,
      callback?: (conversation: Conversation) => Promise<void>,
    ): Promise<Conversation | null> => {
      setIsLoading(true);
      const abortController = newAbortControllerRef();
      const response = await axiosInstance
        .get<Conversation>(`${endpoints.historyRead}/${convId}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          signal: abortController?.signal,
        })
        .then((res) => {
          return updateReferenceLinks(res.data);
        })
        .then(async (conversation) => {
          callback && (await callback(conversation));
          return conversation;
        })
        .catch((err) => {
          console.error('There was an issue fetching your conversation: ', err);
          return null;
        });
      setIsLoading(false);
      return response;
    },
    [axiosInstance, newAbortControllerRef, updateReferenceLinks],
  );

  const historyUpdate = useCallback(
    async (
      conversation: Conversation,
    ): Promise<Response | { res: AxiosResponse; ok: boolean }> => {
      const response = await axiosInstance
        .post<Conversation>(endpoints.historyUpdate, conversation, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          //TODO: Move this logic to a callback passed to historyUpdate instead, to avoid unnecessary useEffect calls.
          // Fetch the chat history if the conversation is new.
          const chatHistoryIncludesId = chatHistory?.some(
            (chat) => chat.id === conversation.id,
          );
          if (!chatHistoryIncludesId) {
            conversationHistoryList();
          }
          return { res, ok: true };
        })
        .catch(() => {
          console.error('There was an issue updating your history.');
          const errRes: Response = {
            ...new Response(),
            ok: false,
            status: 500,
          };
          return errRes;
        });
      return response;
    },
    [axiosInstance, conversationHistoryList, chatHistory],
  );

  const historyDelete = async (
    convId: string,
  ): Promise<Response | AxiosResponse> => {
    try {
      const response = await axiosInstance.delete(
        `${endpoints.historyDelete}/${convId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      return response;
    } catch (error) {
      console.error('There was an issue deleting your history.', error);

      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };

      return errRes;
    }
  };

  const historyDeleteAll = () => {
    if (chatHistory) {
      Promise.all(chatHistory.map((chat) => historyDelete(chat.id)));
    }
  };

  const historyClear = async (convId: string): Promise<Response> => {
    try {
      const response = await axiosInstance.post<Response>(
        endpoints.historyClear,
        {
          conversation_id: convId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (err) {
      console.error('There was an issue clearing your history.');
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    }
  };

  const historyRename = async (
    convId: string,
    title: string,
  ): Promise<Response | AxiosResponse> => {
    try {
      const response = await axiosInstance.post(endpoints.historyRename, {
        data: {
          conversation_id: convId,
          title: title,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch {
      console.error('There was an issue renaming your history.');
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: t('errors.chat_rename_failed'),
      });
      const errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    }
  };

  const getSpeechToken = useCallback(async (): Promise<SpeechToken> => {
    const response = await axiosInstance
      .get(endpoints.getSpeechToken, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        return { authToken: res.data.token, region: res.data.region };
      })
      .catch((err) => {
        return { authToken: null, error: err.response.data };
      });
    return response;
  }, [axiosInstance]);

  const getAnalyzedImage = async (imageUrl: string) => {
    return await axiosInstance
      .post(
        endpoints.analyzeImage,
        {
          image_url: imageUrl,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => res.data);
  };

  const uploadFeedback = async (feedback: Feedback) => {
    return await axiosInstance
      .post(endpoints.feedback, feedback, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        console.error('Failed to upload feedback', err);
        return err.response.status;
      });
  };

  const getBackendVersionInfo = useCallback(async (): Promise<VersionInfo> => {
    const response = await axiosInstance
      .get(endpoints.info, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        return {
          backendVersion: res.data.backend_version,
          imageTag: res.data.imageTag,
          model: res.data.model,
          index: res.data.index,
        };
      })
      .catch((err) => {
        return err.response.status;
      });
    return response;
  }, [axiosInstance]);

  const removeAllOptionFromMetadata = (
    metadata: ProductChoiceData,
  ): ProductChoiceData => {
    const productChoiceKeyMap: ProductChoiceDataPrimaryKeys = {
      brands: 'brand',
      product_types: 'product_type',
      product_groups: 'product_group',
      product_names: 'product_name',
    };
    return Object.entries(metadata).reduce((acc, [key, metadataList]) => {
      const filteredValue = metadataList.filter(
        (item) =>
          item[
            productChoiceKeyMap[
              key as keyof ProductChoiceData
            ] as keyof typeof item
          ] !== 'all',
      );
      return {
        ...acc,
        [key]: filteredValue,
      };
    }, {} as ProductChoiceData);
  };

  const getProductMetadata = useCallback(
    async (
      dataFilter: BusinessSegment[],
      region: string
    ): Promise<ProductChoiceData | void> => {
      if (!region) {
        console.warn('Region is required for product metadata');
        return;
      }

      const response = await axiosInstance
        .get<ProductChoiceData>(endpoints.productFilters, {
          params: {
            data_filter: dataFilter,
            region
          },
          paramsSerializer: (params) => {
            return Object.entries(params)
              .map(([key, value]) => {
                if (value === undefined) {
                  console.warn(`Undefined value for key: ${key}`);
                  return;
                }

                return `${key}=${encodeURIComponent(value)}`;
              })
              .filter(Boolean)
              .join('&');
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          return removeAllOptionFromMetadata(res.data);
        })
        .catch((err) => {
          console.error('Failed to get product filters', err);
        });
      return response;
    },
    [axiosInstance]
  );

  const getProductDocuments = useCallback(async (
    product: string, 
    language?: string,
    region?: string
  ): Promise<ProductManual[]> => {
    try {
      const response = await axiosInstance.get<ProductManual[]>(`${endpoints.productDocuments}/${product}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          lang: language,
          region: region,
        },
      });
      return response.data || [];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('Failed to get product documents', axiosError.message);
        console.error('Error response:', axiosError.response?.data);
        console.error('Request URL:', axiosError.config?.url);
        console.error('Request params:', axiosError.config?.params);
        if (axiosError.response) {
          console.error('Status:', axiosError.response.status);
          console.error('Headers:', axiosError.response.headers);
        }
      } else {
        console.error('An unexpected error occurred:', error);
      }
      throw error;
    }
  }, [axiosInstance]);

  const createInformationRequest = useCallback(
    async (requestData: {
      business_segment: string;
      email: string;
      brand: string;
      product: string;
      product_group?: string;
      comment?: string;
    }) => {
      try {
        const response = await axiosInstance.post(
          '/api/system/information-request',
          requestData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        return response.status;
      } catch (error) {
        console.error('Failed to submit information request:', error);
        throw error;
      }
    },
    [axiosInstance]
  );

  const createIncidentReport = useCallback(
    async (reportData: {
      email: string;
      reason: string;
      description: string;
      conversation_id?: string;
    }) => {
      try {
        const response = await axiosInstance.post(
          '/api/system/incident-report',
          reportData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        return response.status;
      } catch (error) {
        console.error('Failed to submit incident report:', error);
        throw error;
      }
    },
    [axiosInstance]
  );
  const submitSurvey = useCallback(
    async (surveyId: SurveyId, survey: Survey): Promise<number> => {
      try {
        const response = await axiosInstance.post(
          `${endpoints.surveys}/${surveyId}`,
          survey
        );
        return response.status;
      } catch (error) {
        console.error('Failed to submit survey:', error);
        throw error;
      }
    },
    [axiosInstance]
  );

  const getSurveyStatus = useCallback(
    async (surveyId: SurveyId): Promise<boolean> => {
      try {
        const response = await axiosInstance.get(
          `${endpoints.surveys}/${surveyId}/status`
        );
        return response.status === 200;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            return false;
          }
        }
        throw error;
      }
    },
    [axiosInstance]
  );

  return {
    isLoading,
    showAuthMessage,
    sasToken,
    abortConversationRequest,
    getConversationStream,
    getSignedToolMessage,
    historyRead,
    conversationHistoryList,
    historyUpdate,
    historyDelete,
    historyDeleteAll,
    historyClear,
    historyRename,
    loadingHistory,
    getSpeechToken,
    getAnalyzedImage,
    uploadFeedback,
    getBackendVersionInfo,
    getProductMetadata,
    getProductDocuments,
    createInformationRequest,
    createIncidentReport,
    submitSurvey,
    getSurveyStatus,
  };
};
